import React from 'react'
import "./footer.scss"

const Footer = () => {

  return (
    <footer>
      © {new Date().getFullYear()} Skribe. Tous droits réservés
    </footer>
  )
}

export default Footer
