import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Breadcrumb from '../components/breadcrumb/breadcrumbs';

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    // Animation: ENVELOPE OPEN
    setTimeout(() => {
      // Add envelope margin
      document.querySelector(".envelope").style.margin = "300px auto 0";

      // Letter out of Envelope
      if (window.screen.width > 715) {
        document.querySelector(".form-letter").style.bottom = "300px"
      } else {
        document.querySelector(".form-letter").style.bottom = "320px"
      }
      document.querySelector(".form-letter").style.transition = "0.4s 0.4s ease-in"

      // Envelope cap rotate (letter open)
      document.querySelector(".envelope-cap").style.zIndex = "2"
      document.querySelector(".envelope-cap").style.transform = "rotateX(0deg)"
      document.querySelector(".envelope-cap").style.transition = "0.3s ease-in"

    }, 500);
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
    alert('Message envoyé: ' + this.state.value);
    event.preventDefault();
  }

  render() {
    const {data, location} = this.props
    const siteTitle = data.site.siteMetadata?.title || `Title`

    return (
      <Layout location={location} title={siteTitle}>
        <SEO title="Contact" />
        <Breadcrumb location={ location }/>
        <h1>NOUS CONTACTER</h1>

        <div className="contact">
          <div className="envelope">
            <div className="envelope-cap"></div>

            <form className="form-letter" onSubmit={this.handleSubmit}>
              <div>
                <p>Nom</p>
                <label htmlFor="name">
                  <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="1.5em" viewBox="0 0 16 16">
                    <g fill="#004d40">
                      <path d="M3 14s-1 0-1-1s1-4 6-4s6 3 6 4s-1 1-1 1H3zm5-6a3 3 0 1 0 0-6a3 3 0 0 0 0 6z"/>
                    </g>
                  </svg>
                  <input type="text" id="name" name="name" placeholder="Nom"/>
                </label>
              </div>

              <div>
                <p>Email</p>
                <label htmlFor="email">
                  <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="1.5em" viewBox="0 0 24 24">
                    <path d="M4 10v8h16v-8l-8 3l-8-3zm0-4v2l8 3l8-3V6H4zm0-2h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2z" fill="#004d40" fillRule="nonzero"/>
                  </svg>
                  <input type="text" id="email" name="email" placeholder="contact@courriel.fr"/>
                </label>
              </div>

              <div className="form-text">
                <p>Message</p>
                <label>
                  <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="1.5em" viewBox="0 0 24 24">
                    <path fill="none" d="M16.589 9l-1.586-1.586l-9.097 9.097l-.529 2.114l2.114-.528z"/>
                    <path fill="none" d="M19.588 6l-1.586 1.586L16.416 6l1.586-1.586z"/>
                    <path d="M4.003 21c.081 0 .162-.01.242-.03l4-1c.176-.044.337-.135.465-.263L21.003 7.414c.378-.378.586-.88.586-1.414s-.208-1.036-.586-1.414L19.417 3c-.756-.756-2.072-.756-2.828 0L4.296 15.293a1.003 1.003 0 0 0-.263.464l-1 4A1 1 0 0 0 4.003 21zm14-16.586L19.589 6l-1.586 1.586L16.417 6l1.586-1.586zM5.906 16.511l9.097-9.097L16.589 9l-9.098 9.097l-2.114.528l.529-2.114z" fill="#004d40"/>
                  </svg>
                  <textarea value={this.state.value} onChange={this.handleChange} rows="5" placeholder="Écrivez votre message ici."/>
                </label>
              </div>

              <button type="submit" >
                <span>Envoyer
                  <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="1.2em" viewBox="0 0 24 24">
                    <path fill="none" stroke="#004d40" strokeWidth="2" d="M22 3L2 11l18.5 8L22 3zM10 20.5l3-4.5m2.5-6.5L9 14l.859 6.012c.078.546.216.537.306-.003L11 15l4.5-5.5z"/>
                  </svg>
                </span>
              </button>
            </form>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Contact

export const contactQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
