import React from 'react'
import { Link } from "gatsby"
import "./breadcrumbs.scss"

const Breadcrumb = (props) => {
  const pathname = props.location.pathname
  const breadcrumbs = pathname.split('/').filter((elem) => elem !== "");
  const bread = breadcrumbs.slice(0)
  // Remove and stock the last element
  const lastBreadcrumb = breadcrumbs.pop()


  return (
    <nav className="breadcrumbs">
      <Link to="/"> Accueil </Link>
      {breadcrumbs.map(breadcrumb => {
        const slug = pathname.split(bread.filter((elem) => elem !== breadcrumb))
        // Return all elements except the last
        return (
            <Link key={slug} to={ slug[0] }>{ breadcrumb.charAt(0).toUpperCase() + breadcrumb.slice(1).split('-').join(' ') }</Link>
        )
      })}
      <p>{ lastBreadcrumb.charAt(0).toUpperCase() + lastBreadcrumb.slice(1).split('-').join(' ') }</p>
    </nav>
  )
}

export default Breadcrumb
