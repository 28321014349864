import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import "./header.scss"
import skribeLogo from '../../../content/assets/skribe-logo.png'; // skribe logo

const Header = () => {
  const [departScroll, setDepartScroll] = useState(0);

  const queryElement = () => {
    return {
      menuBtn: document.querySelector(".menu-icon span"),
      items: document.querySelector(".nav-items"),
      searchBtn: document.querySelector(".search-icon"),
      cancelBtn: document.querySelector(".cancel-icon"),
      form: document.querySelector("form"),
      navBar: document.querySelector(".header"),
      btnToTop: document.querySelector(".btn-to-top")
    }
  }

  // Disable button searchbar if input is empty
  useEffect(() => {
    const elem = queryElement()
    const searchBarHeader = document.querySelector("#searchBarHeader")
    const inputSearchBarHeader = document.querySelector("#searchBarHeader>input")
    const btnSearchBarHeader = document.querySelector("#searchBarHeader>button")

    searchBarHeader.addEventListener('input', () => {
      if (inputSearchBarHeader.value.length > 0) {
        btnSearchBarHeader.removeAttribute('disabled')
      } else {
        btnSearchBarHeader.setAttribute('disabled', 'disabled')
      }
    })

    // URL change the item navbar design
    const currentLocation = window.location.href
    const navItem = document.querySelectorAll('.nav-items li')

    if(/articles/.test(currentLocation)) {
      navItem[1].firstChild.className="currentItemNavbar"
    }

    navItem.forEach(li => {
      if (li.firstChild.href === currentLocation) {
        li.firstChild.className="currentItemNavbar"
      }
    })

    // Go top when click on btn-to-top
    elem.btnToTop.addEventListener('click', () => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      })
    })
    
  }, [])

  
  if (typeof window !== `undefined`) {
    // Show navbar when scroll up and hide navbar when scroll down
    window.onscroll = () => {
      const elem = queryElement()
      let currentScrollPos = window.pageYOffset

      if (!elem.items.classList.contains("active")) {
        if (departScroll > currentScrollPos || departScroll === 0) {
          elem.navBar.style.top="0"
        } else {
          elem.navBar.style.top="-70px"
        }
      }

      setDepartScroll(currentScrollPos)

      // Display btn-to-top if not top page
      if (window.pageYOffset > 0) {
        elem.btnToTop.classList.add("show")
      } else {
        elem.btnToTop.classList.remove("show")
      }
    }
  }
  
  
  // Show items menu
  const showItems = () => {
    const btn = queryElement()

    btn.menuBtn.classList.add("hide")
    btn.items.classList.add("active")
    btn.searchBtn.classList.add("hide")
    btn.cancelBtn.classList.add("show")
  }

  // Hide items menu or hide the searchbar
  const cancelItems = () => {
    const btn = queryElement()

    btn.menuBtn.classList.remove("hide")
    btn.items.classList.remove("active")
    btn.searchBtn.classList.remove("hide")
    btn.cancelBtn.classList.remove("show")
    btn.form.classList.remove("active")
  }

  // Show the searchbar
  const searchBar = () => {
    const btn = queryElement()

    btn.form.classList.add("active")
    btn.searchBtn.classList.add("hide")
    btn.cancelBtn.classList.add("show")
  }

  return (
    <nav className="header">
      {/* MENU ICON */}
      <button className="menu-icon" onClick={ showItems }>
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.1em" viewBox="0 0 1536 1280">
            <path d="M1536 1088v128q0 26-19 45t-45 19H64q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19H64q-26 0-45-19T0 704V576q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19H64q-26 0-45-19T0 192V64q0-26 19-45T64 0h1408q26 0 45 19t19 45z"/>
          </svg>
        </span>
      </button>
      {/* LOGO */}
      <Link className="logo-header" to="/" itemProp="url">
        <img src={skribeLogo} alt="lettre ᛊ (skribe logo)"/>
        <p>ᚲᚱᛁᛒᛖ</p>
      </Link>
      {/* NAV-ITEMS */}
      <ul className="nav-items">
        <li><Link to="/">Accueil</Link></li>
        <li><Link to="/articles/">Articles</Link></li>
        <li><Link to="/contact/">Contact</Link></li>
      </ul>
      {/* SEARCH ICON */}
      <button className="search-icon" onClick={ searchBar }>
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="1.6em"  viewBox="0 0 24 24">
            <path d="M16.325 14.899l5.38 5.38a1.008 1.008 0 0 1-1.427 1.426l-5.38-5.38a8 8 0 1 1 1.426-1.426zM10 16a6 6 0 1 0 0-12a6 6 0 0 0 0 12z" fillRule="evenodd"/>
          </svg>
        </span>
      </button>
      {/* CANCEL ICON */}
      <button className="cancel-icon" onClick={ cancelItems }>
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="1.3em" viewBox="0 0 11 11">
            <path d="M2.2 1.19l3.3 3.3L8.8 1.2a.67.67 0 0 1 .5-.2a.75.75 0 0 1 .7.7a.66.66 0 0 1-.2.48L6.49 5.5L9.8 8.82c.13.126.202.3.2.48a.75.75 0 0 1-.7.7a.67.67 0 0 1-.5-.2L5.5 6.51L2.21 9.8a.67.67 0 0 1-.5.2a.75.75 0 0 1-.71-.71a.66.66 0 0 1 .2-.48L4.51 5.5L1.19 2.18A.66.66 0 0 1 1 1.7a.75.75 0 0 1 .7-.7a.67.67 0 0 1 .5.19z"/>
          </svg>
        </span>
      </button>
      {/* SEARCH BAR */}
      <form id="searchBarHeader" action="#">
        <input type="search" placeholder="Rechercher" required/>
        <button type="submit" disabled="disabled">
          <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="1.3em"  viewBox="0 0 24 24">
            <path d="M16.325 14.899l5.38 5.38a1.008 1.008 0 0 1-1.427 1.426l-5.38-5.38a8 8 0 1 1 1.426-1.426zM10 16a6 6 0 1 0 0-12a6 6 0 0 0 0 12z" fillRule="evenodd"/>
          </svg>
        </button>
      </form>
      {/* BTN TO TOP */}
      <div className="btn-to-top">
          <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="1.3em" viewBox="0 0 24 24">
            <path d="M6 4h12v2H6zm.707 11.707L11 11.414V20h2v-8.586l4.293 4.293l1.414-1.414L12 7.586l-6.707 6.707z"/>
          </svg>
      </div>
    </nav>
  )
}
export default Header
